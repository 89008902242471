import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomeQuery } from "../__generated__/gatsby-types"

import GlobalCss from "../components/GlobalCss"

import { Global } from '@emotion/core'

const PageCss = () => (
  <Global styles={`

    

  `} />
)

import styled from "@emotion/styled"

const IndexPage = styled.div`
  /* display: flex; */
  min-height: 100vh;

  header {
    background-color: #f4deda;
    font-style: italic;
    /* width: 50%; */
  }

  main {
    /* width: 50%; */
  }

`

const IndexItem = styled.article`
  a {
    font-size: 30px;
    text-decoration: none;
    color: #000;
  }
`



export interface HomeTemplateProps {
  data: HomeQuery
}

const HomeTemplate: React.FunctionComponent<HomeTemplateProps> = ({ data }) => {
  console.log("HomeTemplate")
  if (!data?.site?.siteMetadata || !data.site.siteMetadata?.title) {
    throw new Error("Missing data for the home page.")
  }
  const siteTitle = data.site.siteMetadata.title
  const recipes = data.allMarkdownRemark.edges

  return (
    <IndexPage>
      <GlobalCss />
      <PageCss />
      <SEO title="vgtrnsndwch" />
      <header>
        <h1 className="name">vgtrnsndwch</h1>
        <h2 className="tagline">A recipe collection of vegetarian sandwiches</h2>
      </header>
      <main>
      {recipes.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <IndexItem key={node.fields.slug}>
              <Link to={node.fields.slug}>{title}</Link>
          </IndexItem>
        )
      })}
      </main>
    </IndexPage>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`
